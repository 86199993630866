import React, { useEffect, useState } from "react";
import { ButtonAction } from "../../components/Patients/ButtonAction";
import { HeaderMobile } from "../../components/Patients/HeaderMobile";
import { HomeIndicator } from "../../components/Patients/HomeIndicator";
import { TextFieldBasic } from "../../components/Patients/TextFieldBasic";
import "./style.css";
import PasswordGenerator from "../../components/Patients/PasswordGenerator/PasswordGenerator";
import axios from "axios";
import { api_url } from "../../globarvariable";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import ModalCentered from "../../components/Patients/Modal/Modal";

export const InscriptionPatientMobile = (): JSX.Element => {

  const[stateButton, setStateButton] = useState('disabled');
  const[lastName, setLastName] = useState('');
  const[firstName, setFirstName] = useState('');
  const[email, setEmail] = useState('');
  const[error, setError] = useState(false);
  const[errorMessage, setErrorMessage] = useState('');
  const [password, setPassword] = useState('');
  const [childOfChildValue, setChildOfChildValue] = useState('');
  const userId = useSelector((action : any) => action.userIdReducer);
  const accessToken = localStorage.getItem('accessToken');
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [cgu, setCgu]= useState(false);
  const [mentionLegale, setMentionLegale]= useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [textModal, setTextModal] = useState('');
  const [titleModal, setTitleModal] = useState('');

  const handleChildOfChildValueChange = (value) => {
    setChildOfChildValue(value);
    setPassword(value);
  };

  const handleSubmit =  async () => {
    setLoading(true);
    const formData = {
      last_name: lastName,
      first_name: firstName,
      email: email.toLowerCase(),
      password: password,
    };

    fetch(api_url+'api/users/'+userId, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.json())
      .then((data) => {
        if(data.id){
          setTimeout(() => {
            setLoading(false);
          }, 2000);

            navigate('/patient');
          
        }
        else{
            setError(true);
            setErrorMessage(data.detail)
        }
      })
      .catch((error) => {
        console.error('Error inserting data:', error);
      });

  }

  useEffect(() => {

    if(lastName != '' && firstName != '' && email != '' && password != '' && cgu === true && mentionLegale === true){
      setIsDisabled(false);
    }

  }, [lastName,firstName,email,password, cgu, mentionLegale]);

  useEffect(() => {

    const fetchData = () => {
      const response =  axios.get(api_url+'api/users/'+userId, {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) =>{
        setLastName(response.data.last_name);
        setFirstName(response.data.first_name);
        setEmail(response.data.email);
      })
      //
    }


    fetchData();

  }, []);

  const handleCGU = () => {
    setCgu(true);
    if(cgu === true){
      setCgu(false);
    }
  }

  const handleMentionLegale = () => {
    setMentionLegale(true);
    if(mentionLegale === true){
      setMentionLegale(false);
    }
  }

  const handleModal = (type) => {

    if(type == 'cgu'){
      setTextModal('Les présentes Conditions Générales d\'Utilisation (ci-après les "CGU") ont pour objet de définir les modalités d\'accès et d\'utilisation de APAORA. En accédant ou en utilisant le Site, vous acceptez sans réserve les présentes CGU. Si vous n\'acceptez pas ces CGU, nous vous invitons à ne pas utiliser le Site.')
      setTitleModal('Conditions Générales d\'Utilisation')
    }
    else if(type == 'mention'){
      setTextModal('Toute exploitation non autorisée du Site ou de l\'un de ses éléments sera considérée comme constitutive d\'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de la propriété intellectuelle.')
      setTitleModal('Mention légale')
    }
    else if(type == 'confidentialite'){
      setTextModal('Chez APAORA, nous nous engageons à protéger la confidentialité et la sécurité de vos données personnelles. Cette politique de confidentialité explique comment nous collectons, utilisons, partageons et protégeons vos informations personnelles lorsque vous utilisez notre site')
      setTitleModal('Politiques de confidentialité')
    }

    setShowModal(true)

  }

  const onClose = () => {
    setShowModal(false);
  }
  
  return (
    <div className="inscription-mobile">
      <div className="div-3">
        <p className="p">Pour commencer, nous allons créer votre compte.</p>
        <div className="text-wrapper-2">Vos informations personnelles</div>
        <div className="text-wrapper-3">Votre mot de passe</div>
        <TextFieldBasic
          background="transparent"
          className="text-field-basic-instance"
          state="initial"
          text={lastName ? lastName :""}
          type="classic"
          onChange={(e) => setLastName(e.target.value)}
          placeholder="Nom"
        />
        <TextFieldBasic
          background="transparent"
          className="design-component-instance-node"
          state="initial"
          text={firstName ? firstName : ""}
          type="classic"
          onChange={(e) => setFirstName(e.target.value)}
          placeholder="Prénom"
        />
        <TextFieldBasic
          background="transparent"
          className="text-field-basic-2"
          state="initial"
          text={email ? email : ""}
          type="classic"
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Adresse email"
        />
        <PasswordGenerator handleValueChange={handleChildOfChildValueChange}/>
        <p className="cgu patient">
          <input type='checkbox' onChange={handleMentionLegale} /> En cochant cette case, vous reconnaissez avoir pris connaissance et accepté nos <a onClick={() => handleModal('cgu')}>Conditions Générales d'Utilisation</a> et nos <a onClick={() => handleModal('mention')}>mentions légales</a>.
        </p>
        <p className="info patient">
          <input type='checkbox' onChange={handleCGU} /> En soumettant ce formulaire, j’accepte que les informations saisies soient exploitées dans le cadre de mon utilisation de la plateforme APAORA. <a onClick={() => handleModal('confidentialite')}>Consultez notre politique de confidentialité</a>
        </p>
        <button
          className="button-action-instance sign-up btn btn-primary"
          color="dark"
          onClick={handleSubmit}
          type="button"
          disabled={isDisabled}> Continuer </button>
        {loading && <ClipLoader className='loader' color="#123abc" loading={loading} />}
        <div className="keyboard">
          <div className="overlap">
            <HomeIndicator className="home-indicator-instance" mode="light" />
          </div>
        </div>
        <HeaderMobile className="header-mobile-instance" logoPropertyDefault="/img/logo-1.svg" type="logo" />
      </div>
      <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= {titleModal}
        subtitle= ''
        message= {textModal}
      />
    </div>
  );
};
