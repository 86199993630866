import React, { useState } from 'react'
import { Logo } from '../../components/Patients/Logo';
import { api_url } from '../../globarvariable';
import { useNavigate } from 'react-router-dom';
import './style.css';
import { useDispatch } from 'react-redux';
import { logged } from '../../actions/logged';
import { ClipLoader, RingLoader } from 'react-spinners';

const SignIn = () => {

    const[email, setEmail] = useState('');
    const[password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [rememberMe, setRememberMe] = useState(true);
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    const handleChangeEmail = (e) => {
        setEmail(e.target.value)
    }

    const handlePassword = (e) => {
        setPassword(e.target.value)
    }

    const handleAuthentication= () => {
      setLoading(true);
        const formData = {
            email: email.toLowerCase(),
            password: password
        };

        fetch(api_url+'api/auth/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(formData),
        })
            .then((response) => response.json())
            .then((data) => {
              if(data.access_token){
                localStorage.setItem('accessToken', data.access_token);
                dispatch(logged());
                navigate('/medecin');
              }
              else{
                setError('Impossible de se connecter car le nom d\'utilisateur et le mot de passe sont incorrects');
              }
                
            })
            .catch((error) => {
              console.error('Unaunthenticated', error);
            });

            setTimeout(() => {
              setLoading(false);
            }, 2000);
    }

    const handleKeyPress = (event) => {
      if (event.key === 'Enter') {
        // Trigger the button click when Enter key is pressed
        handleAuthentication();
      }
    };

    const handleRememberMe = () => {
      if(rememberMe === false){
        setRememberMe(true);
      }
      else{
        setRememberMe(false);
      }

    }
  return (
    <div>
       
        <section className="vh-100 bg-image" id="sign-up">
        <div className="mask d-flex align-items-center h-100 gradient-custom-3">
          <div className="container h-100">
            <div className="row d-flex justify-content-center align-items-center h-100">
              <div
                className="col-12 col-md-9 col-lg-7 col-xl-5"
                id="card-first-div"
              >
                <div className="card">
                  <div className="card-body p-5">
                  <Logo
          className="class logo-auth"
          property1="default"
          propertyDefault= "/img/logo-1.svg"
        />
                    <form>  
                      <div className="form-outline mb-4 login">
                      {loading && <ClipLoader color="#123abc" loading={loading} />}
                      <a
                        type="button"
                        className="btn btn-primary btn-block mb-4"
                        href='https://wallet.esw.esante.gouv.fr/auth/?response_type=code&scope=openid scope_all&client_id=observance-apaora&state=ZFWZJ91VWvDVquJN89yPCbvjkxw&redirect_uri=https:%2F%2Fapaora.com%2Fpsc&nonce=mlUAqh0tAHtrPru6hm9Zz7D5sY8kSKb9h3UEty2UPKc&acr_values=eidas1'
                      >
                        Se connecter avec Pro Sante Connect
                      </a>
                      </div>
                      <div className="form-outline mb-4 login">
                      <a
                        type="button"
                        className="btn btn-primary btn-block mb-4 login-button"
                        href='/login-medecin'
                      >
                        Se connecter avec Login/Mot de passe
                      </a>
                      </div>

                    
                      
                      <div className='error'>{error}</div>
                      <div className="text-center">
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default SignIn
