import React, { useEffect, useState } from "react";
import { ButtonAction } from "../../components/Patients/ButtonAction";
import { HeaderMobile } from "../../components/Patients/HeaderMobile";
import { HomeIndicator } from "../../components/Patients/HomeIndicator";
import { TextFieldCode } from "../../components/Patients/TextFieldCode";
import "./style.css";
import { Navigate, useNavigate } from "react-router-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { InscriptionPatientMobile } from "../InscriptionPatientMobile";
import { api_url } from "../../globarvariable";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { useDispatch } from "react-redux";
import { getUserId } from "../../actions";
import ModalCentered from "../../components/Patients/Modal/Modal";

export const OnboardingMobile = (): JSX.Element => {
  const[stateButton, setStateButton] = useState('initial');
  const[error, setError] = useState(false);
  const[errorMessage, setErrorMessage] = useState('');
  const [childOfChildValue, setChildOfChildValue] = useState('');
  const [userIdParams, setuserIdParams] = useState('');
  const [code, setCode] = useState('');
  const accessToken = localStorage.getItem('accessToken');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [ showModal, setShowModal ] = useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const searchParams = new URLSearchParams(window.location.search);
  const key = searchParams.get('key');

  const handleChildOfChildValueChange = (value) => {
    setChildOfChildValue(value);
  };

  const maskString = (str, start, end) => {
    const maskedPart = '*'.repeat(end - start);
    return str.substring(0, start) + maskedPart + str.substring(end);
  };

  const maskEmail = (email) => {
    // Trouver l'indice du caractère '@'
    const atIndex = email.indexOf('@');
  
    // Si l'indice '@' n'est pas trouvé ou si l'adresse email est trop courte, retourner l'adresse inchangée
    if (atIndex === -1 || atIndex >= email.length - 1) {
      return email;
    }
  
    // Diviser l'adresse e-mail en deux parties: partie avant '@' et partie après '@'
    const username = email.substring(0, atIndex);
    const domain = email.substring(atIndex + 1);
  
    // Masquer une partie du nom d'utilisateur (par exemple, les caractères après le deuxième caractère)
    const maskedUsername = maskString(username, 2, username.length);
  
    // Masquer une partie du domaine (par exemple, tous les caractères sauf les trois premiers)
    const maskedDomain = maskString(domain, 0, Math.max(0, domain.length - 3)) + domain.substring(domain.length - 3);
  
    // Construire l'adresse e-mail masquée en combinant le nom d'utilisateur masqué et le domaine masqué
    const maskedEmail = `${maskedUsername}@${maskedDomain}`;
  
    return maskedEmail;
  };


  useEffect(() => {

  const response =  axios.get(api_url+'api/patients/me', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${accessToken}`,
    }
  }).then((response) =>{
      setEmail(response.data.email);
    })

  })


  const handleNavigation = () => {
    setLoading(true);
    const formData = {
      code: childOfChildValue,
    };

    fetch(api_url+'api/patients/verify', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${key}`,
      },
      body: JSON.stringify(formData),
    }).then((response) => response.json()).then((data) => {
      if(data.id){
        dispatch(getUserId(data.id));
        localStorage.setItem('accessToken', data.token);
        navigate('/inscription-patient');
      }
      else{
          setError(true);
          setErrorMessage('Votre code est éronné')
      }
      setTimeout(() => {
        setLoading(false);
      }, 2000);
    })
    .catch((error) => {
      console.error('Error data:', error);
    });

  }

  const handleResendCode = () => {
    const response =  axios.get(api_url+'api//auth/resend-code', {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${key}`,
      }
    }).then((response) =>{
      setShowModal(true)
    })
    
  }

  /*useEffect(() => {
    // Run this effect only once on component mount
    if(childOfChildValue == code && code != ''){
      setStateButton('initial');
      setErrorMessage('');
    }
    else if(childOfChildValue != code && code != ''){
      setErrorMessage('Votre code est éronné');
    }
  }, [childOfChildValue]);*/

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const userIdParam = searchParams.get('user_id');

    if (userIdParam !== null) {
      setuserIdParams(userIdParam);
    }

    const fetchData = () => {
      const response =  axios.get(api_url+'api/users/'+userIdParam, {
        headers: {
          'Content-Type': 'application/json',
           Authorization: `Bearer ${accessToken}`,
        },
      }).then((response) =>{
        setCode(response.data.verif_code);
      })
      //
    }


    fetchData();

  }, []);

  const onClose = () => {
    setShowModal(false);
    window.location.reload();
  }

  return (
    <div className="onboarding-mobile">
      <div className="div-2">
        <p className="text-wrapper">Bienvenue sur l’espace de suivi de votre activité APA</p>
        <p className="merci-de-rentrer">Merci de rentrer votre code envoyé sur {maskEmail(email)} </p>
        <div className="group">
          <p className="vous-n-avez-plus-de">
            Vous n’avez plus de code ?<br />
            Pas de panique ! Nous vous le renvoyons par SMS
          </p>
          <div className="text-wrapper-2 resend-code" onClick={handleResendCode}>Renvoyer le code</div>
        </div>
        
       <ButtonAction
          className="button-action-instance onboarding"
          color="dark"
          size="primary"
          state={stateButton == "disabled" ? "disabled" : "initial"}
          text="Continuer"
          phoneNumber=""
          onClick={handleNavigation}
        />
        {loading && <ClipLoader className='loader' color="#123abc" loading={loading} />}
        <div className="keyboard">
          <div className="overlap">
            <HomeIndicator className="home-indicator-instance" mode="light" />
          </div>
        </div>
        <TextFieldCode className="text-field-code-number" state="initial" handleValueChange={handleChildOfChildValueChange}/>
        {errorMessage != '' && <p className="error-message">{errorMessage}</p> }
        <HeaderMobile className="header-mobile-instance" logoPropertyDefault="/img/logo.svg" type="logo" />
      </div>
      <ModalCentered
        show={showModal}
        onHide={() => onClose()}
        title= ''
        subtitle= 'Nouveau code envoyé'
        message='Un nouveau code vous a été envoyé par SMS.'
      />
    </div>
  );
};
